import React from 'react';
import Layout from '../components/layout/layout';
import DemoHero from '../components/demo/sections/hero';

export default function Demo({ initialData }) {
  const openWhatsApp = () => {
    const url = "intent://send/?phone=254105279316#Intent;scheme=whatsapp;end";
    window.location.href = url;
  };
  
  return (
    <Layout initialData={initialData}>
      <DemoHero />
      {/* WhatsApp Chat Button */}
      <button onClick={openWhatsApp} className="fixed bottom-4 right-4 md:bottom-6 md:right-6 bg-whatsapp hover:bg-whatsapp-hover text-white rounded-full px-4 py-2 md:px-6 md:py-3 flex items-center gap-2 text-sm md:text-base shadow-lg">
        <span className="text-lg md:text-xl">💬</span>
        Run Basic Test
      </button>
    </Layout>
  );
}